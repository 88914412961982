export default {
   // shop data
   async setShop(context) {
      let {data} = await shortFetch('basic_root_data/get_shop_data', ...skip(4), false);
      if (data.ok) context.commit('intiShop', data.data);
   },
   
   async updateShop(context, userData = {}) {
      let {data} = await shortFetch('shop_data/update_shop_data', userData, 'POST');
      if (data.ok) context.dispatch('setShop');
   },

}