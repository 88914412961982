<template>
   <div class="print_invoice_money_details_design_2" id="print_invoice_money_details_design_2">
      <div class="row justify-content-between mb-3">
         <!-- cartons & weight  -->
         <div class="col-5">
            <div class="info-holder">
               <span class="label">{{ $t('lang_item_number') }}</span> :
               <span class="number">{{ cartons }}</span>
            </div>

            <div class="info-holder">
               <span class="label">{{ $t('lang_total_weight') }}</span> :
               <span class="number">{{ total_weight }}kg</span>
            </div>
         </div>
         
         <!-- totals  -->
         <div class="col-5 w-fit">
            <div class="info-holder">
               <span class="label">{{ $t('lang_invoice_amount') }}</span> :
               <span class="number">{{ $filters.setCurrency(invoice_before_discount, currency) }}</span>
            </div>

            <div class="info-holder">
               <span class="label">{{ $t('lang_discounts') }}</span> :
               <span class="number">{{ $filters.setCurrency(discount, currency) }}</span>
            </div>

            <div class="info-holder">
               <span class="label">{{ $t('lang_delivery_price') }}</span> :
               <span class="number">{{ $filters.setCurrency(total_delivery_amount, currency) }}</span>
            </div>

            <div class="info-holder">
               <span class="label">{{ $t('lang_amount_remain') }}</span> :
               <span class="number">{{ $filters.setCurrency(invoice_after_discount, currency) }}</span>
            </div>

            <!-- debts  -->
            <div class="info-holder">
               <span class="label">{{ $t('lang_old_balance') }}</span> :
               <span class="number">{{ $filters.setCurrency(old_debts, currency) }}</span>
            </div>

            <div class="info-holder">
               <span class="label">{{ $t('lang_current_balance') }}</span> :
               <span class="number">{{ $filters.setCurrency(total_debts, currency) }}</span>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
export default {
   props: {
      currency: String,
      invoice_type: { type: String, default: 0 },

      invoice_before_discount:  { type: [String, Number], default: 0 },
      discount:                 { type: [String, Number], default: 0 },
      total_delivery_amount:    { type: [String, Number], default: 0 },
      invoice_after_discount:   { type: [String, Number], default: 0 },

      cartons:      { type: [String, Number], default: 0 },
      total_weight: { type: [String, Number], default: 0 },

      old_debts:   { type: [String, Number], default: 0 },
      total_debts: { type: [String, Number], default: 0 },

      isReturnInvoice: { type: Boolean, default: false },
   },
}
</script>


<style scoop lang="scss">
   @use '@sass/helpers' as *;

   .print_invoice_money_details_design_2 {
      .info-holder {
         display: flex;
         align-items: center;
         gap: rem(5);
         margin-top: rem(5);
         font-size: rem(13);
         
         .label {
            width: rem(80);
         }

         .number {
            width: rem(150);
            padding: 0 rem(5);
            border: solid rem(1) #777;
            text-align: center;
            font-weight: bold;
            background-color: rgba(119, 119, 119, .3);
         }
      }
   }

</style>