<template>
   <div class="print_header_design_3" id="print_header_design_3" :class="$isArb() ? '': 'flex-row-reverse'" :style="`background-image: url(${URL_PATH + UPLOADS_IMG_FOLDER_NAME}shop/${shopHeaderBg}) !important`">
      <div class="logo">
         <img :src="`${URL_PATH + UPLOADS_IMG_FOLDER_NAME}shop/${shopImg}`" class="rounded-img-75 no-img-loader" alt="Shop Logo">
      </div>

      <div class="info" :class="shopHeaderBg == '' ? '' : 'overlay-bg'" >
         <h1 class="title">{{ shopCommercialName }}</h1>
         <h4>{{ shopName }}</h4>
         <p>{{ shopDescription  }}</p>
         <div class="phones">
            <span v-if="shopPhone != ''">{{ shopPhone }}</span>
            <span v-if="shopSecondPhone != ''">{{ shopSecondPhone }}</span>
         </div>
         <p>{{ $t(title) }}</p>
      </div>
      
      <div class="logo">
         <img :src="`${URL_PATH + UPLOADS_IMG_FOLDER_NAME}shop/${shopSecondLogo}`" class="rounded-img-75 no-img-loader" alt="Shop Logo">
      </div>
   </div>
</template>

<script>
import { reactive, toRefs, computed } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n';

export default {
   props: {
      title: {type: [String, Boolean],default: false},
   },
   setup (props) {
      const store = useStore();
      const { t } = useI18n();
      const shop  = store.getters['shop/getShop'];

      const state = reactive({
         shopName: shop.name,
         shopImg: shop.image,
         shopCommercialName: shop.commercialName,
         shopSecondLogo: shop.secondaryLogo,
         shopPhone: shop.phone,
         shopSecondPhone: shop.secondaryPhone,
         shopAddress: shop.address,
         shopDescription: shop.description,
         shopHeaderBg: shop.headerBgImage.trim(),

         URL_PATH,
         UPLOADS_IMG_FOLDER_NAME,
      });
   
      return {
         ...toRefs(state),
      }
   }
}
</script>


<style lang="scss" scoped>
@use '@sass/helpers' as *;

.print_header_design_3 {
   display: flex;
   justify-content: space-between;
   align-items: flex-start;
   margin-bottom: rem(20);
   min-height: rem(150);
   height: fit-content;

   background-size: cover !important;
   background-color: transparent !important;
   background-repeat: no-repeat !important;
   background-position: center !important;

   @media print { background-position: 40% !important; }
   
   .info {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      gap: rem(5);
      text-align: center;
      text-transform: capitalize;
      color: #222;
      
      
      &.overlay-bg {
         background: rgba(0, 0, 0, 0.6);
         padding: rem(5) rem(10);
         color: #fff;
      }

      .title {
         font-weight: bolder;
      }

      .phones {
         display: flex;
         align-items: center;
         justify-content: center;
         gap: 2rem;      
      }

   }
}
</style>