export default class ValidatePattern {
   errMsg;
   pattern = /^[A-Z]{2}\d+$/;
   type = 'code';

   constructor(type, pattern) {
      if (!isEmpty(type)) this.type = type;
      if (!isEmpty(pattern)) this.pattern = pattern;
   }

   validate(value) {
      if (!this.pattern.test(value.toString()) && value != '') return this.setErr();
      else return true;
   }

   setErr() {
      if (this.type == 'code') this.errMsg = ['lang_code_pattern_err'];

      return this.errMsg;
   }
}