export default {
   // user data
   getUser(state) {
      return state.user;
   },

   // get is user logged in
   getAuth(state) {
      return state.auth;
      // state.auth ?? localStorage.getItem('diamond_vue_token') ? ;
   },
}