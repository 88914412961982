export default {
   // user data 
   intiUser(state, user = null) {
      state.user = modifyColumnNames(user);
   },

   // set auth 
   setAuth(state, auth = false) {
      state.auth = auth;
      
      if (!auth) {
         state.user        = null;
         state.settings    = null;
         state.permissions = null;
      }
   },
}