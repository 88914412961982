import { createRouter, createWebHistory } from 'vue-router';
import {print}      from './print.router';
import {customers}  from './customers.router';
import {suppliers}  from './suppliers.router';
import {sales}      from './sales.router';
import {delegates}  from './delegates.router';

const home = [
   {
      path: '/',
      name: 'home',
      component: () => import('../views/sidebar/Home.vue'),
   },
];

const shortcuts = [
   {
      path: '/shortcuts',
      name: 'shortcuts',
      component: () => import('../views/sidebar/Shortcuts.vue'),
   },
   {
      path: '/shortcuts/users',
      name: 'users',
      meta: {
         permissionName: 'users',
      },
      component: () => import('../views/pages/Users.vue'),
   },
   {
      path: '/shortcuts/settings',
      name: 'settings',
      meta: {
         permissionName: 'settings',
      },
      component: () => import('../views/pages/Settings.vue'),
   },
   {
      path: '/shortcuts/drivers',
      name: 'drivers',
      meta: {
         permissionName: 'drivers',
      },
      component: () => import('../views/pages/Drivers.vue'),
   },
   {
      path: '/shortcuts/users_permissions',
      name: 'users_permissions',
      component: () => import('../views/pages/UsersPermissions.vue'),
   },
   {
      path: '/shortcuts/governorate',
      name: 'governorate',
      component: () => import('../views/pages/Governorate.vue'),
   },
   {
      path: '/shortcuts/regions',
      name: 'regions',
      component: () => import('../views/pages/Regions.vue'),
   },
];

const expenses = [
   {
      path: '/expenses',
      name: 'expenses',
      component: () => import('../views/sidebar/Expenses.vue'),
   },
   {
      path: '/expenses/expenses_types',
      name: 'expenses_types',
      component: () => import('../views/pages/ExpensesTypes.vue'),
   },
];

const safes = [
   {
      path: '/safe',
      name: 'safe',
      component: () => import('../views/sidebar/Safe.vue'),
   },
   {
      path: '/safe/stocks',
      name: 'stocks',
      component: () => import('../views/pages/Stocks.vue'),
   },
   {
      path: '/safe/price_difference_types',
      name: 'price_difference_types',
      component: () => import('../views/pages/PriceDifferenceTypes.vue'),
   },
   {
      path: '/safe/safes_operations',
      name: 'safes_operations',
      component: () => import('../views/pages/SafesOperations.vue'),
   },
   {
      path: '/safe/safes_operations_reports',
      name: 'safes_operations_reports',
      component: () => import('../views/pages/SafesOperationsReports.vue'),
   },
   {
      path: '/safe/transfer_money_to_safe',
      name: 'transfer_money_to_safe',
      component: () => import('../views/pages/TransferMoneyToSafe.vue'),
   },
   {
      path: '/safe/safe_reports',
      name: 'safe_reports',
      component: () => import('../views/pages/SafeReports.vue'),
   },
   {
      path: '/safe/balance',
      name: 'balance',
      component: () => import('../views/pages/SafeMoney.vue'),
   },
   {
      path: '/safe/profits',
      name: 'profits',
      component: () => import('../views/pages/SafeProfits.vue'),
   },
];

const reports = [
   {
      path: '/reports',
      name: 'reports',
      component: () => import('../views/sidebar/Reports.vue'),
   },
   {
      path: '/purchases/purchases_reports',
      name: 'purchases_reports',
      component: () => import('../views/pages/PurchasesReports.vue'),
   },
];

const product = [
   {
      path: '/product_page/:productId',
      name: 'product_page',
      component: () => import('../views/pages/ProductPage.vue'),
   },
   {
      path: '/products',
      name: 'products',
      component: () => import('../views/sidebar/Products.vue'),
   },
   {
      path: '/products/categories',
      name: 'categories',
      component: () => import('../views/pages/Categories.vue'),
   },
   {
      path: '/products/sub_category',
      name: 'sub_category',
      component: () => import('../views/pages/SubCategory.vue'),
   },
   {
      path: '/products/add_products',
      name: 'add_products',
      component: () => import('../views/pages/AddProducts.vue'),
   },
   {
      path: '/products/products_store/:productName?',
      name: 'products_store',
      component: () => import('../views/pages/ProductsStore.vue'),
   },
   {
      path: '/products/stores',
      name: 'stores',
      component: () => import('../views/pages/Stores.vue'),
   },
   {
      path: '/products/store_reports',
      name: 'store_reports',
      component: () => import('../views/pages/StoreReports.vue'),
   },
   {
      path: '/products/corrupted_stores',
      name: 'corrupted_stores',
      component: () => import('../views/pages/CorruptedStores.vue'),
   },
   {
      path: '/products/transfer_products',
      name: 'transfer_products',
      component: () => import('../views/pages/TransferProducts.vue'),
   },
   {
      path: '/products/transfer_products_reports',
      name: 'transfer_products_reports',
      component: () => import('../views/pages/TransferProductsReports.vue'),
   },
   {
      path: '/products/product_sales_records_in_year',
      name: 'product_sales_records_in_year',
      component: () => import('../views/pages/ProductSalesRecordsInYear.vue'),
   },
];

const invoices = [
   {
      path: '/invoices',
      name: 'invoices',
      component: () => import('../views/sidebar/Invoices.vue'),
   },
   {
      path: '/invoices/sales_invoice',
      name: 'invoices/sales_invoice',
      component: () => import('../views/pages/AddInvoice.vue'),
   },
   {
      path: '/invoices/edit_sales_invoice/:invoiceId',
      name: 'edit_sales_invoice',
      component: () => import('../views/pages/EditSalesInvoice.vue'),
   },
   {
      path: '/invoices/edit_sales_pre_invoice/:invoiceId',
      name: 'edit_sales_pre_invoice',
      component: () => import('../views/pages/EditSalesPreInvoice.vue'),
   },
   {
      path: '/invoices/pre_invoice',
      name: 'pre_invoice',
      component: () => import('../views/pages/AddPreInvoice.vue'),
   },
   {
      path: '/invoices/pre_invoice_reports',
      name: 'pre_invoice_reports',
      component: () => import('../views/pages/PreInvoiceReports.vue'),
   },
   {
      path: '/invoices/pre_invoice_orders/:invoice?/:currency?',
      name: 'pre_invoice_orders',
      component: () => import('../views/pages/PreInvoiceOrders.vue'),
   },
   {
      path: '/invoices/purchase_invoice',
      name: 'purchase_invoice',
      component: () => import('../views/pages/AddPurchaseInvoice.vue'),
   },
   {
      path: '/invoices/purchase_invoice_reports/:invoiceId?/:currency?',
      name: 'purchase_invoice_reports',
      component: () => import('../views/pages/PurchaseInvoiceReports.vue'),
   },
   {
      path: '/invoices/purchase_invoice_orders/:invoice?/:currency?',
      name: 'purchase_invoice_orders',
      component: () => import('../views/pages/PurchaseInvoiceOrders.vue'),
   },
   {
      path: '/invoices/edit_purchase_invoice/:invoiceId',
      name: 'edit_purchase_invoice',
      component: () => import('../views/pages/EditPurchaseInvoice.vue'),
   },
   {
      path: '/invoices/return_invoice',
      name: 'return_invoice',
      component: () => import('../views/pages/AddReturnInvoice.vue'),
   },
   {
      path: '/invoices/return_invoice_reports/:invoiceId?/:currency?',
      name: 'return_invoice_reports',
      component: () => import('../views/pages/ReturnInvoiceReports.vue'),
   },
   {
      path: '/invoices/return_invoice_orders/:invoice?/:currency?/:type?',
      name: 'return_invoice_orders',
      component: () => import('../views/pages/ReturnInvoiceOrders.vue'),
   },
   {
      path: '/invoices/edit_return_invoice/:invoiceId',
      name: 'edit_return_invoice',
      component: () => import('../views/pages/EditReturnInvoice.vue'),
   },
];

const shop = [
   {
      path: '/shop',
      name: 'shop',
      component: () => import('../views/pages/ShopData.vue'),
   },
];

const backup = [
   {
      path: '/backup',
      name: 'backup',
      component: () => import('../views/pages/Backup.vue'),
   },
];

const notification = [
   {
      path: '/notifications',
      name: 'notifications',
      component: () => import('../views/pages/Notifications.vue'),
   },
];

const purchases = [
   {
      path: '/purchases',
      name: 'purchases',
      component: () => import('../views/sidebar/Purchases.vue'),
   },
   {
      path: '/purchases/purchases_reports',
      name: 'purchases_reports',
      component: () => import('../views/pages/PurchasesReports.vue'),
   },
   {
      path: '/purchases/purchases_invoice',
      name: 'purchases_invoice',
      // component: () => import('../views/pages/PurchasesInvoice.vue'),
   },
];

print.forEach((obj, i) => {
   if (!obj.meta) {
      obj.meta = {
         showNav: false,
         showGlobalSearch: false,
         fixedBtns: false,
      }

   } else {
      if (!obj.meta.showNav) obj.meta.showNav = false;
      if (!obj.meta.showGlobalSearch) obj.meta.showGlobalSearch = false;
      if (!obj.meta.fixedBtns) obj.meta.fixedBtns = false;
   }

});



const routes = [
   ...home,
   ...shortcuts,
   ...print,
   ...expenses,
   ...safes,
   ...sales,
   ...delegates,
   ...suppliers,
   ...customers,
   ...reports,
   ...product,
   ...invoices,
   ...shop,
   ...backup,
   ...purchases,
   ...notification,

   {
      path: '/login',
      name: 'login',
      component: () => import('../views/log/Login.vue'),
      meta: { 
         showNav: false,
         auth: false,
         showGlobalSearch: false,
         fixedBtns: false,
      },
   },
   {
      path: '/language',
      name: 'language',
      component: () => import('../views/sidebar/Language.vue'),
   },
   {
      path: '/:catchAll(.*)',
      component: () => import('../views/error/404.vue'),
      meta: { 
         showNav: false,
         showGlobalSearch: false,
         fixedBtns: false,
      }
   },
];

const router = createRouter({
   history: createWebHistory(process.env.BASE_URL),
   routes
});


export default router
