import globalJs from "./globalJs";
import i18n     from "@/language/i18n";

const t = i18n.global.t;

const global = {
   // ~~~~~~~~~~~~~> live image on selecting file
   liveImg : {
      mounted(input, binding) {
         if (input.type != 'file') return;
         if (!binding.value) return;

         input.addEventListener('change', function(e) {

            let output = el(binding.value) || false;
            if (!output) return;

            output.setAttribute('src', URL.createObjectURL(e.target.files[0]));
            output.addEventListener('load', function () {
               URL.revokeObjectURL(output.src) // free memory
            });
         });
      }
   },

   // ~~~~~~~~~~~~~> make focus on input
   focus: {
      mounted(el, binding) {
         if (binding.value != undefined) {
            if (!binding.value) return;
         }

         if (window.innerWidth >= 768) el.focus();
      }
   },

   // ~~~~~~~~~~~~~> when inserting value make it count for phone number
   countPhoneNumber: {
      mounted(el, binding) {
         let phoneNumberCount  = parseInt(binding.value);
         let input             = el;
         let span              = input.nextElementSibling;

         if (input.value.trim().length !== 0) span.innerHTML = phoneNumberCount - input.value.length;
      
         input.addEventListener('keyup', function() {
            span.innerHTML = phoneNumberCount - this.value.length;
      
            if (span.innerHTML < 0) span.innerHTML = 0;
            if (span.innerHTML > phoneNumberCount) span.innerHTML = phoneNumberCount;
         });

         input.addEventListener('blur', function() {
            if (input.value.trim().length === 0) span.innerHTML = phoneNumberCount;
         })
      },
   },

   // ~~~~~~~~~~~~~> global events on elements
   globalJs : {
      mounted() { globalJs() },
      updated() { globalJs() }
   },

   tooltipOverflow: {
      mounted(element, binding) {
         const tooltip = el('[data-tooltip]');

         element.onmousemove = function(e) {
            let el = element;

            tooltip.classList.remove('d-none');
            tooltip.style.transition = 'opacity .3s .05s ease';
      
            let y             = el.getBoundingClientRect().top + document.documentElement.scrollTop;
            let x             = el.getBoundingClientRect().left + document.documentElement.scrollLeft;
            let ttWidth       = tooltip.getBoundingClientRect().width;
            let ttHeight      = tooltip.getBoundingClientRect().height;
            let text          = el.getAttribute('tt-text').trim();
            let direction     = el.getAttribute('tt-dir').trim();
      
            let isLight       = el.hasAttribute('tt-light');
            let isBold        = el.hasAttribute('tt-fw-bold');
            let bgClr         = el.getAttribute('tt-bg-clr');
            let textClr       = el.getAttribute('tt-text-clr');
            let isShadow      = el.hasAttribute('tt-shadow');
            let fs            = el.getAttribute('tt-fs');
            let windowWidth   = window.innerWidth;
            let elWidth       = el.getBoundingClientRect().width;
            let elHeight      = el.getBoundingClientRect().height;
            let tooltipMargin = 8;

            tooltip.innerHTML = t(text);

            if (direction == 'left') {
               tooltip.style.left      = `${x  - tooltipMargin + ttWidth * -1}px`;
               tooltip.style.top       = `${y + (elHeight / 2)}px`;
               tooltip.style.right     = `auto`;
               tooltip.style.bottom    = `auto`;
               tooltip.style.opacity   = `1`;
               tooltip.style.transform = `translate(0%, -50%)`;
            }
            
            if (direction == 'right') {
               tooltip.style.right     = `${(windowWidth - x - elWidth - ttWidth - tooltipMargin)}px`;
               tooltip.style.top       = `${y + (elHeight / 2)}px`;
               tooltip.style.left      = `auto`;
               tooltip.style.bottom    = `auto`;
               tooltip.style.opacity   = `1`;
               tooltip.style.transform = `translate(0%, -50%)`;
            }
      
            if (direction == 'top') {
               tooltip.style.top       = `${(y - tooltipMargin )}px`;
               tooltip.style.left      = `${(x + (elWidth / 2))}px`;
               tooltip.style.right     = `auto`;
               tooltip.style.bottom    = `auto`;
               tooltip.style.opacity   = `1`;
               tooltip.style.transform = `translate(-50%, -100%)`;
            }
      
            if (direction == 'bottom') {
               tooltip.style.top       = `${y + elHeight + tooltipMargin}px`;
               tooltip.style.left      = `${x + (elWidth / 2)}px`;
               tooltip.style.right     = `auto`;
               tooltip.style.bottom    = `auto`;
               tooltip.style.opacity   = `1`;
               tooltip.style.transform = `translate(-50%, 0%)`;
            }
      
            if (el.hasAttribute('tt-bg-clr')) tooltip.style.backgroundColor = bgClr;
            else tooltip.style.backgroundColor = 'black';
      
            if (el.hasAttribute('tt-text-clr')) tooltip.style.color = textClr;
            else tooltip.style.color = 'white';
      
            if (el.hasAttribute('tt-fs')) tooltip.style.fontSize = fs;
            else tooltip.style.fontSize = '1rem';
      
            if (el.hasAttribute('tt-fw-bold')) tooltip.style.fontWeight = 'bold';
            else tooltip.style.fontWeight = 'normal';
      
            if (isShadow) tooltip.classList.add('shadow')
            else tooltip.classList.remove('shadow')
      
            if (isLight) {
               tooltip.style.backgroundColor = 'white';
               tooltip.style.color = 'black';
            }
      
            if (isBold) tooltip.style.fontWeight = 'bold';
         };

         element.onmouseleave = function(e) {
            if (!tooltip.classList.contains('d-none')) tooltip.classList.add('d-none');

            tooltip.style.transition = 'none';
            tooltip.style.left       = 'auto';
            tooltip.style.right      = 'auto';
            tooltip.style.top        = 'auto';
            tooltip.style.bottom     = 'auto';
            tooltip.style.opacity    = 0;
         };
      },
   }
};


export default global;
