<template>
   <div class="print_footer_8cm" id="print_footer_8cm">
      
      <!-- address  -->
      <div v-if="shopAddress" class="contact">
         <i class="fa-solid fa-location-dot"></i>
         <span>{{ shopAddress }}</span>
      </div>

      <!-- second address  -->
      <div v-if="shopSecondAddress" class="contact">
         <i class="fa-solid fa-location-dot"></i>
         <span>{{ shopSecondAddress  }}</span>
      </div>

      <!-- phone -->
      <div v-if="shopPhone" class="contact">
         <i class="fa-solid fa-phone"></i>
         <span>{{ shopPhone  }}</span>
      </div>

      <!-- facebook -->
      <div v-if="shopFacebook" class="contact">
         <i class="fa-brands fa-facebook"></i>
         <span>{{ shopFacebook }}</span>
      </div>
      <!-- instagram -->
      <div v-if="shopInstagram" class="contact">
         <i class="fa-brands fa-instagram"></i>
         <span>{{ shopInstagram }}</span>
      </div>

      <!-- notes -->
      <div v-if="shopNote" class="note">
         <h6 class="title">{{ $t('lang_notes') }}</h6>
         <p :class="$isArb() ?'': 'rtl'">{{ shopNote }}</p>
      </div>
   </div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import { useStore } from 'vuex'

export default {
   setup () {
      const store = useStore();
      const shop  = store.getters['shop/getShop'];

      const state = reactive({
         shopPhone: shop.phone || false,
         shopAddress: shop.address || false,
         shopSecondAddress: shop.secondaryAddress || false,
         shopFacebook: shop.facebook || false,
         shopInstagram: shop.instagram || false,
         shopNote: shop.note || false,
      });

      return {
         ...toRefs(state),
      }
   }
}
</script>

<style lang="scss" scoped>
@use '@sass/helpers' as *;

#print_footer_8cm {
   padding: .5rem;
   margin-top: auto;
   display: grid;
   gap: rem(5);

   span,i {
      font-size: rem(13);
   }

   .contact {
      display: flex;
      align-items: center;
      gap: rem(10);
   }

   .note {
      font-size: rem(13);
      margin-top: rem(8);
      text-align: center;
      
      .title {
         text-transform: capitalize;
         font-size: rem(13);
         margin-bottom: rem(5);
         font-weight: bold;
      }
   }
}
</style>