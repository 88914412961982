<template>
   <div class="print_invoice_contact_details_design_2" id="print_invoice_contact_details_design_2">
      <div class="row">
         <!-- supplier  -->
         <div v-if="supplierCheck" class="col-5">
            <h3 class="title">{{ $t('lang_supplier') }}</h3>
            <div class="info-holder">
               <span class="label">{{ $t('lang_supplier_name') }}</span> :
               <span>{{ supplier.SpName }}</span>
            </div>
            <div class="info-holder" v-if="supplier.SpPhone != ''">
               <span class="label">{{ $t('lang_phone') }}</span> : 
               <span>{{ supplier.SpPhone }}</span>
            </div>
            <div class="info-holder" v-if="supplier.SpAddress != ''">
               <span class="label">{{ $t('lang_address') }}</span> : 
               <span>{{ supplier.SpAddress }}</span>
            </div>
         </div>

         <!-- customer  -->
         <div v-if="customerCheck" class="col-5">
            <h3 class="title">{{ $t('lang_client') }}</h3>
            <div class="info-holder">
               <span class="label">{{ $t('lang_commercial_name') }}</span> :
               <span>{{ customer.CustShopName }}</span>
            </div>
            <div class="info-holder">
               <span class="label">{{ $t('lang_customer_name') }}</span> :
               <span>{{ customer.CustName }}</span>
            </div>
            <div class="info-holder" v-if="customer.CustPhone != ''">
               <span class="label">{{ $t('lang_phone') }}</span> : 
               <span>{{ customer.CustPhone }}</span>
               <span v-if="customer.CustSecondPhone != 0"> / {{ customer.CustSecondPhone }}</span>
            </div>
            <div class="info-holder" v-if="customer.RName != ''">
               <span class="label">{{ $t('lang_region') }}</span> : 
               <span>{{ customer.RName }}</span>
            </div>
            <div class="info-holder" v-if="customer.CustAddress != ''">
               <span class="label">{{ $t('lang_address') }}</span> : 
               <span>{{ customer.CustAddress }}</span>
            </div>
         </div>

         <!-- driver  -->
         <div v-if="driverCheck" class="col-3">
            <h3 class="title">{{ $t('lang_driver') }}</h3>
            <div class="info-holder">
               <span class="label">{{ $t('lang_driver_name') }}</span> :
               <span>{{ driver.DName }}</span>
            </div>
            <div class="info-holder" v-if="driver.DPhone != ''">
               <span class="label">{{ $t('lang_phone') }}</span> : 
               <span>{{ driver.DPhone }}</span>
            </div>
         </div>
         
         <!-- delegates  -->
         <div v-if="delegateCheck" class="col-4">
            <h3 class="title">{{ $t('lang_delegate') }}</h3>
            <div class="info-holder">
               <span class="label">{{ $t('lang_delegate_name') }}</span> :
               <span>{{ delegate.DelName }}</span>
            </div>
            <div class="info-holder" v-if="delegate.DelPhone != ''">
               <span class="label">{{ $t('lang_phone') }}</span> : 
               <span>{{ delegate.DelPhone }}</span>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
import { reactive, toRefs, onUpdated} from 'vue'

export default {
   props: {
      customer: { type: [Object, Boolean], default: false },
      supplier: { type: [Object, Boolean], default: false },
      driver:   { type: [Object, Boolean], default: false },
      delegate: { type: [Object, Boolean], default: false },
      invoice_type: String,
   },

   setup(props) {
      const state = reactive({
         customerCheck: false,
         supplierCheck: false,
         driverCheck: false,
         delegateCheck: false,
      });
      
      if (props.customer !== false) {
         if (!isEmpty(props.customer)) state.customerCheck = true;
      }
      if (props.supplier !== false) {
         if (!isEmpty(props.supplier)) state.supplierCheck = true;
      }
      
      if (!isEmpty(props.driver) && !isEmpty(props.driver.DName)) state.driverCheck       = true;
      if (!isEmpty(props.delegate) && !isEmpty(props.delegate.DelName)) state.delegateCheck = true;

      return {
         ...toRefs(state)
      }
   },

}
</script>

<style scoped lang="scss">
@use '@sass/helpers' as *;

.print_invoice_contact_details_design_2 {
   .title {
      font-size: rem(18);
      margin-bottom: rem(10);
      color: var(--print-main-clr);
      font-weight: bold;
      text-transform: capitalize;
   }

   .info-holder {
      display: flex;
      gap: rem(15);
      margin-bottom: rem(5);
      font-size: rem(14);
   }
}


</style>