<template>
   <div class="table-responsive">
      <table :class="[tableClass, 'table', verticalTable ? 'table-vertical' : '']" v-table>
         <thead :class="[headClass, headSticky ? 'head-sticky' : '']">
            <tr>
               <th v-if="selectDelete">
                  <div class="select-all" v-once>
                     <label for="chose-all"> {{ $t('lang_pick_all') }} </label>
                     <input class="form-check-input" type="checkbox" id="chose-all" v-select-all>
                  </div>
               </th>
               <slot name="head"></slot>
            </tr>
            <tr v-if="headFilters">
               <slot name="filters"></slot>
            </tr>

            <slot v-if="verticalTable" name="body"></slot>
         </thead>

         <transition-group v-if="!verticalTable && hasBodyTransition" name="table-filters-transition" tag="tbody">
            <slot name="body"></slot>
         </transition-group>

         <tbody v-if="!verticalTable && !hasBodyTransition">
            <slot name="body"></slot>
         </tbody>
         <tfoot>
            <slot name="footer"></slot>
         </tfoot>
      </table>
   </div>
</template>

<script>
export default {
   name:'Tables',
   props: {
      selectDelete: { type: Boolean, default: false},
      tableClass:   { type: String, default: 'table'},
      headClass:    { type: String, default: null},
      headFilters:  { type: Boolean, default: false},
      verticalTable: { type: Boolean, default: false },
      hasBodyTransition: { type: Boolean, default: false },
      headSticky:        { type: Boolean, default: true },
   },
}
</script>