export default {
   // user settings data
   async setSettings(context) {
      let {data} = await shortFetch(API_URL + 'basic_root_data/get_user_settings', ...skip(4), false);
      if (data.ok) context.commit('intiSettings', data.data);
   },

   async updateSettings(context, userSettingsData = {}) {
      let {data} = await shortFetch(API_URL + 'settings/update_settings', userSettingsData, 'POST', ...skip(2), false);
      if (data.ok) {
         context.dispatch('setSettings');
         return true;
      }
   },
}