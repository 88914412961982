export default {
   // user permissions data
   async setPermissions(context) {
      let {data} = await shortFetch('basic_root_data/get_user_permissions', ...skip(4), false);
      if (data.ok) context.commit('intiPermissions', data.data);
   },

   async updatePermissions(context, userPermissionsData = {}) {
      let {data} = await shortFetch('users_permissions/update_permissions', userPermissionsData, 'POST', ...skip(2), false);
      if (data.ok) context.dispatch('setPermissions');
   },

   async updateAllPermissions(context, userPermissionsData = {}) {
      let {data} = await shortFetch('users_permissions/update_all_permission', userPermissionsData, 'POST', ...skip(2), false);
      if (data.ok) context.dispatch('setPermissions');
   },
}