<template>
   <h2 class="main-heading" id="main-heading">
      <slot></slot>
   </h2>
</template>

<script>
export default {
   name: 'MainHeading',
}
</script>

<style lang="scss" scoped>
@use '@sass/helpers' as *;
@use '@sass/variables' as *;

.main-heading {
   margin-top: rem(10);
   margin-bottom: rem(16);
   padding-bottom: rem(10);
   text-transform: capitalize;
   border-bottom: 2px solid var(--dark-gray);
   @include br(sm) {
      padding-left: calc(var(--global_search_width) + rem(60));
   }
}

</style>