import {createStore} from 'vuex';
import manageUserDataModule from './user/index.js';
import manageUserSettingsModule from './settings/index.js';
import manageUserPermissionsModule from './permissions/index.js';
import manageShopData from './shop/index.js';
import configurations from './config/index.js';
import manageNotifications from './notifications/index.js';
import classesModule from './classes/index.js';


const store = createStore({
   modules: {
      user:          manageUserDataModule,
      settings:      manageUserSettingsModule,
      permissions:   manageUserPermissionsModule,
      shop:          manageShopData,
      notifications: manageNotifications,
      config:        configurations,
      classes:       classesModule,
   }
});

export default store;