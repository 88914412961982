export default class ValidateZeroValue {
   errMsg;

   validate(value) {
      if (value.trim() == 0) return this.setErr();
      else return true;
   }

   setErr() {
      this.errMsg = ['lang_empty_field_err'];
      return this.errMsg;
   }
}