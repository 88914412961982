import gettersMethods from './getters';
import {CRUDManager}  from '@/utils/classes/CRUDManager.js';


export default {
   namespaced : true,

   state() {
      return {
         CRUDManager,
      }
   },

   getters: gettersMethods,
};
