<template>
   <div>
      <div id="loading-response" class="d-none">
         <div class="custom-loader"></div>
      </div>
   </div>
</template>

<script>

   export default {
      name: 'dataLoader',
   }

</script>

<style scoped lang="scss">
   @use '@sass/helpers' as *;

   #loading-response {
      position: fixed;
      top: rem(10);
      left: 4vw;
      z-index: 12345678;
      display: grid;
      place-items: center;
      width: rem(70);
      height: rem(70);
      pointer-events: none;

      padding: rem(5);
      border-radius: rem(5);
      background-color: rgba(#000, .65);
      -webkit-backdrop-filter: blur(5px);
      -moz-backdrop-filter: blur(5px);
      -ms-backdrop-filter: blur(5px);
      -o-backdrop-filter: blur(5px);
      backdrop-filter: blur(5px);
      border: solid rem(1) hsl(0, 0%, 40%);

      .custom-loader {
         --hue: 200;
         --size: 50px;
         --border: 10px;
         --speed: 1s;
         --blur: var(--border);
         --y: calc((var(--size) * -0.5) + (var(--border) * 0.5));

         position: absolute;
         aspect-ratio: 1;
         width: var(--border);
         transform: rotate(0deg) translateY(var(--y));
         border-radius: 50%;
         background: white;
         animation: spin var(--speed) infinite linear;
      }

      .custom-loader::before {
         content: "";
         position: absolute;
         inset: calc(var(--border) * -0.5);
         border-radius: 50%;
         background: white;
         filter: blur(var(--blur));
         z-index: -1;
      }

      .custom-loader::after {
         content: "";
         width: var(--size);
         aspect-ratio: 1;
         position: absolute;
         top: 0%;
         left: 50%;
         translate: -50% 0;
         background: conic-gradient(
            white,
            hsl(var(--hue), 100%, 80%),
            hsl(var(--hue), 100%, 40%),
            transparent 65%
            );
         border-radius: 50%;
         
         -webkit-mask: radial-gradient(
            transparent calc(((var(--size) * 0.5) - var(--border)) - 1px),
            white calc((var(--size) * 0.5) - var(--border))
         );
         -moz-mask: radial-gradient(
            transparent calc(((var(--size) * 0.5) - var(--border)) - 1px),
            white calc((var(--size) * 0.5) - var(--border))
         );
         -o-mask: radial-gradient(
            transparent calc(((var(--size) * 0.5) - var(--border)) - 1px),
            white calc((var(--size) * 0.5) - var(--border))
         );
         -ms-mask: radial-gradient(
            transparent calc(((var(--size) * 0.5) - var(--border)) - 1px),
            white calc((var(--size) * 0.5) - var(--border))
         );
         mask: radial-gradient(
            transparent calc(((var(--size) * 0.5) - var(--border)) - 1px),
            white calc((var(--size) * 0.5) - var(--border))
         );
      }
   }
   
</style>