<template>
   <div class="print_footer_design_1" id="print_footer_design_1">
      
      <p v-if="shopSecondAddress" class="info">
         {{ shopSecondAddress }}
      </p>
      
      <div class="social" :class="$isArb() ?'': 'align-items-end'" v-if="shopFacebook || shopInstagram || shopTelegram || shopWhatsapp">
         <div class="social_row">
            <div v-if="shopFacebook">
               <i class="fa-brands fa-facebook"></i>
               <span>{{ shopFacebook }}</span>
            </div>
            <div v-if="shopInstagram">
               <i class="fa-brands fa-instagram"></i>
               <span>{{ shopInstagram }}</span>
            </div>
         </div>
         <div class="social_row">
            <div v-if="shopWhatsapp">
               <i class="fa-brands fa-whatsapp"></i>
               <span>{{ shopWhatsapp }}</span>
            </div>
            <div v-if="shopTelegram">
               <i class="fa-brands fa-telegram"></i>
               <span>{{ shopTelegram }}</span>
            </div>
         </div>
      </div>

      <p v-if="shopNote" class="info" :class="$isArb() ?'': 'rtl'">
         {{ shopNote }}
      </p>
   </div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import { useStore } from 'vuex'

export default {
   setup () {
      const store = useStore();
      const shop  = store.getters['shop/getShop'];

      const state = reactive({
         shopSecondAddress: shop.secondaryAddress || false,
         shopFacebook: shop.facebook || false,
         shopInstagram: shop.instagram || false,
         shopTelegram: shop.telegram || false,
         shopWhatsapp: shop.whatsapp || false,
         shopNote: shop.note || false,
      });

      return {
         ...toRefs(state),
      }
   }
}
</script>

<style lang="scss" scoped>
@use '@sass/helpers' as *;

.print_footer_design_1 {
   padding: .5rem 1rem;
   margin-top: auto;
   font-size: 1rem;
   font-size: rem(14);

   .info {
      margin-block: rem(2.5);
   }

   .social {
      > * {
         display: flex;
         align-items: center;
         gap: rem(10);
      }
   }
}
</style>