export const print = [
   {
      path: '/print_delegates_reports',
      name: 'print_delegates_reports',
      component: () => import('../views/print/PrintDelegatesReports.vue'),
      props: route => ({
         id: route.query.id,
         from: route.query.from,
         to: route.query.to,
      }),
   },
   {
      path: '/print_sales_reports',
      name: 'print_sales_reports',
      component: () => import('../views/print/PrintSalesReports.vue'),
      props: route => ({
         from: route.query.from,
         to: route.query.to,
         invoice: route.query.invoice,
         product: route.query.product,
         category: route.query.category,
         subCategory: route.query.subCategory,
         supplier: route.query.supplier,
         customer: route.query.customer,
         region: route.query.region,
         delegate: route.query.delegate,
         page: route.query.page,
      }),
   },
   {
      path: '/print_invoice_reports',
      name: 'print_invoice_reports',
      component: () => import('../views/print/PrintInvoiceReports.vue'),
      props: route => ({
         invoice: route.query.invoice,
         customer: route.query.customer,
         currency: route.query.currency,
         type: route.query.type,
      }),
   },
   {
      path: '/print_pre_invoice_orders',
      name: 'print_pre_invoice_orders',
      component: () => import('../views/print/PrintPreInvoiceOrders.vue'),
      props: route => ({
         from: route.query.from,
         to: route.query.to,
         invoice: route.query.invoice,
         product: route.query.product,
         category: route.query.category,
         subCategory: route.query.subCategory,
         supplier: route.query.supplier,
         customer: route.query.customer,
         region: route.query.region,
         delegate: route.query.delegate,
         page: route.query.page,
      }),
   },
   {
      path: '/print_invoice/:invoiceId?/:type?',
      name: 'print_invoice',
      component: () => import('../views/print/PrintInvoice.vue'),
   },
   {
      path: '/print_invoice_8cm/:invoiceId?',
      name: 'print_invoice_8cm',
      component: () => import('../views/print/PrintInvoice8cm.vue'),
   },
   {
      path: '/print_purchase_invoice/:invoiceId?',
      name: 'print_purchase_invoice',
      component: () => import('../views/print/PrintPurchaseInvoice.vue'),
   },
   {
      path: '/print_purchase_invoices_reports',
      name: 'print_purchase_invoices_reports',
      component: () => import('../views/print/PrintPurchaseInvoicesReports.vue'),
      props: route => ({
         invoice: route.query.invoice,
         currency: route.query.currency,
         supplier: route.query.supplier,
      }),
   },
   {
      path: '/print_purchase_invoice_orders',
      name: 'print_purchase_invoice_orders',
      component: () => import('../views/print/PrintPurchaseInvoiceOrders.vue'),
      props: route => ({
         from: route.query.from,
         to: route.query.to,
         invoice: route.query.invoice,
         product: route.query.product,
         category: route.query.category,
         subCategory: route.query.subCategory,
         supplier: route.query.supplier,
         page: route.query.page,
      }),
   },
   {
      path: '/print_purchase_invoice_8cm/:invoiceId?',
      name: 'print_purchase_invoice_8cm',
      component: () => import('../views/print/PrintPurchaseInvoice8cm.vue'),
   },
   {
      path: '/print_customers_payments/:invoiceId',
      name: 'print_customers_payments',
      component: () => import('../views/print/PrintCustomersPayments.vue'),
   },
   {
      path: '/print_customers_payments_8cm/:invoiceId',
      name: 'print_customers_payments_8cm',
      component: () => import('../views/print/PrintCustomersPayments8cm.vue'),
   },
   {
      path: '/print_customers_reports/',
      name: 'print_customers_reports',
      component: () => import('../views/print/PrintCustomersReports.vue'),
      props: route => ({
         currency: route.query.currency,
         customer: route.query.customer,
         page: route.query.page,
      }),
   },
   {
      path: '/print_customers_all_debts_reports/',
      name: 'print_customers_all_debts_reports',
      component: () => import('../views/print/PrintCustomersAllDebtsReports.vue'),
      props: route => ({
         customer: route.query.customer,
         page: route.query.page,
      }),
   },
   {
      path: '/print_store_reports',
      name: 'print_store_reports',
      component: () => import('../views/print/PrintStoreReports.vue'),
      props: route => ({
         page: route.query.page,
         type: route.query.type,
         currency: route.query.currency,
         store: route.query.store,
         supplier: route.query.supplier,
         category: route.query.category,
         sub_category: route.query.subCategory,
      }),
   },
   {
      path: '/print_transfer_products_reports',
      name: 'print_transfer_products_reports',
      component: () => import('../views/print/PrintTransferProductsReports.vue'),
      props: route => ({
         from: route.query.from,
         to: route.query.to,
         product: route.query.product,
         type: route.query.type,
         store: route.query.store,
         corruptedStore: route.query.corrupted_store,
         category: route.query.category,
         subCategory: route.query.sub_category,
      }),
   },
   {
      path: '/print_return_invoice/:type/:invoiceId?/',
      name: 'print_return_invoice',
      component: () => import('../views/print/PrintReturnInvoice.vue'),
   },
   {
      path: '/print_return_invoices_reports',
      name: 'print_return_invoices_reports',
      component: () => import('../views/print/PrintReturnInvoicesReports.vue'),
      props: route => ({
         invoice: route.query.invoice,
         type: route.query.type,
         currency: route.query.currency,
         supplier: route.query.supplier,
         customer: route.query.customer,
      }),
   },
   {
      path: '/print_return_invoice_orders',
      name: 'print_return_invoice_orders',
      component: () => import('../views/print/PrintReturnInvoiceOrders.vue'),
      props: route => ({
         from: route.query.from,
         to: route.query.to,
         invoice: route.query.invoice,
         product: route.query.product,
         category: route.query.category,
         subCategory: route.query.subCategory,
         supplier: route.query.supplier,
         customer: route.query.customer,
         region: route.query.region,
         delegate: route.query.delegate,
         type: route.query.type,
         page: route.query.page,
      }),
   },
   {
      path: '/print_products_sold_by_customers',
      name: 'print_products_sold_by_customers',
      component: () => import('../views/print/PrintProductsSoldByCustomers.vue'),
      props: route => ({
         from: route.query.from,
         to: route.query.to,
         product: route.query.product,
         category: route.query.category,
         subCategory: route.query.subCategory,
         supplier: route.query.supplier,
         customer: route.query.customer,
         region: route.query.region,
         delegate: route.query.delegate,
         page: route.query.page,
      }),
   },
   {
      path: '/print_unsold_customers_report',
      name: 'print_unsold_customers_report',
      component: () => import('../views/print/PrintUnsoldCustomersReport.vue'),
      props: route => ({
         from: route.query.from,
         to: route.query.to,
         customer: route.query.customer,
         region: route.query.region,
         delegate: route.query.delegate,
         visitDay: route.query.visitDay,
         page: route.query.page,
      }),
   },
   {
      path: '/print_product_sales_records_in_year',
      name: 'print_product_sales_records_in_year',
      component: () => import('../views/print/PrintProductSalesRecordsInYear.vue'),
      props: route => ({
         product: route.query.product,
         category: route.query.category,
         subCategory: route.query.subCategory,
         supplier: route.query.supplier,
         customer: route.query.customer,
         region: route.query.region,
         delegate: route.query.delegate,
         visitDay: route.query.visitDay,
         type: route.query.type,
         page: route.query.page,
      }),
   },
   {
      path: '/print_customer_sales_records_in_year',
      name: 'print_customer_sales_records_in_year',
      component: () => import('../views/print/PrintCustomerSalesRecordsInYear.vue'),
      props: route => ({
         product: route.query.product,
         category: route.query.category,
         subCategory: route.query.subCategory,
         supplier: route.query.supplier,
         customer: route.query.customer,
         region: route.query.region,
         delegate: route.query.delegate,
         visitDay: route.query.visitDay,
         type: route.query.type,
         page: route.query.page,
      }),
   },
   {
      path: '/print_reveal_customers_account',
      name: 'print_reveal_customers_account',
      component: () => import('../views/print/PrintRevealCustomersAccount.vue'),
      props: route => ({
         from: route.query.from,
         to: route.query.to,
         customer: route.query.customer,
         delegate: route.query.delegate,
         page: route.query.page,
      }),
   },
   {
      path: '/print_reveal_suppliers_account',
      name: 'print_reveal_suppliers_account',
      component: () => import('../views/print/PrintRevealSuppliersAccount.vue'),
      props: route => ({
         from: route.query.from,
         to: route.query.to,
         supplier: route.query.supplier,
         page: route.query.page,
      }),
   },
   {
      path: '/print_gifts_sales_reports',
      name: 'print_gifts_sales_reports',
      component: () => import('../views/print/PrintGiftsSalesReports.vue'),
      props: route => ({
         product: route.query.product,
         category: route.query.category,
         subCategory: route.query.subCategory,
         supplier: route.query.supplier,
         customer: route.query.customer,
         region: route.query.region,
         delegate: route.query.delegate,
         type: route.query.type,
         page: route.query.page,
      }),
   },
   {
      path: '/print_products_sales_records',
      name: 'print_products_sales_records',
      component: () => import('../views/print/PrintProductsSalesRecords.vue'),
      props: route => ({
         product: route.query.product,
         category: route.query.category,
         subCategory: route.query.subCategory,
         supplier: route.query.supplier,
         customer: route.query.customer,
         region: route.query.region,
         delegate: route.query.delegate,
         type: route.query.type,
         page: route.query.page,
      }),
   },
   {
      path: '/print_delegates_target_reports',
      name: 'print_delegates_target_reports',
      component: () => import('../views/print/PrintDelegateTargetReports.vue'),
      props: route => ({
         from: route.query.from,
         to: route.query.to,
         delegate: route.query.delegate,
         category: route.query.category,
         subCategory: route.query.subCategory,
         supplier: route.query.supplier,
         page: route.query.page,
      }),
   },
   {
      path: '/print_customers_dealing_with_delegates',
      name: 'print_customers_dealing_with_delegates',
      component: () => import('../views/print/PrintCustomersDealingWithDelegates.vue'),
      props: route => ({
         from: route.query.from,
         to: route.query.to,
         delegate: route.query.delegate,
         page: route.query.page,
      }),
   },
   {
      path: '/print_inventory_manager_report',
      name: 'print_inventory_manager_report',
      component: () => import('../views/print/PrintInventoryManagerReport.vue'),
      props: route => ({
         invoices_id: route.query.invoices_id,
      }),
   },
];