<template>
   <div class="print_header_8cm" id="print_header_8cm">
      
      <div class="title">
         <img :src="`${URL_PATH + UPLOADS_IMG_FOLDER_NAME}shop/${shopImg}`" class="rounded-img-50 no-img-loader" alt="Shop Logo">
         <h4>{{ shopName }}</h4>
         <p class="desc">{{ titleMsg  }}</p>
      </div>

      <!-- invoice date & time  -->
      <div class="invoice_date_time">
         <span>{{ time }}</span>
         <span>{{ date }}</span>
      </div>

      <table v-if="invoiceNumber" class="table table-white border-dark my-0" v-table>
         <thead>
            <tr>
               <th>{{ $t('lang_invoice_number') }}</th>
               <td>{{ invoiceNumber }}</td>
            </tr>
         </thead>
      </table>
   </div>
</template>

<script>
import { reactive, toRefs, computed } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n';

export default {
   props: {
      currency: String,
      invoiceNumber: {
         type: [String, Number, Boolean],
         default: false,
      },
      title: {
         type: [String, Boolean],
         default: false,
      },
      date: {
         type: String,
         default: getDate(),
      },
      time: {
         type: String,
         default: getTime(),
      }
   },

   setup (props) {
      const store = useStore();
      const { t } = useI18n();
      const shop  = store.getters['shop/getShop'];

      const state = reactive({
         shopName: shop.name,
         shopImg: shop.image,

         URL_PATH,
         UPLOADS_IMG_FOLDER_NAME,
      });

      const titleMsg = computed(()=> {
         if (props.title == false) return store.getters['shop/getShop'].description;
         else return t(props.title);
      });
   
      return {
         ...toRefs(state),

         titleMsg,
      }
   }
}
</script>


<style lang="scss" scoped>
@use '@sass/helpers' as *;

.print_header_8cm {
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
   height: fit-content;


   .title {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: rem(2.5);
      text-align: center;
      text-transform: capitalize;
      margin-bottom: rem(8);

      h1,h2,h3,h4 {
         font-weight: bold;
         text-transform: capitalize;
      }

   }

   .invoice_date_time {
      display: flex;
      gap: rem(8);
      font-size: rem(14);
   }
}
</style>