export const delegates = [
   {
      path: '/delegates',
      name: 'delegates',
      component: () => import('../views/sidebar/Delegates.vue'),
   },
   {
      path: '/delegates/add_delegates',
      name: 'add_delegates',
      meta: {
         permissionName: 'delegates',
      },
      component: () => import('../views/pages/Delegates.vue'),
   },
   {
      path: '/delegates/add_delegates_target',
      name: 'add_delegates_target',
      component: () => import('../views/pages/AddDelegatesTarget.vue'),
   },
   {
      path: '/delegates/delegates_target_reports',
      name: 'delegates_target_reports',
      component: () => import('../views/pages/DelegatesTargetReports.vue'),
   },
   {
      path: '/delegates/customers_dealing_with_delegates',
      name: 'customers_dealing_with_delegates',
      component: () => import('../views/pages/CustomersDealingWithDelegates.vue'),
   },
   {
      path: '/delegates/delegate_debts',
      name: 'delegate_debts',
      component: () => import('../views/pages/DelegatesDebts.vue'),
   },
];