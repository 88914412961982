<template>
   <div class="print_invoice_contact_details_8cm" id="print_invoice_contact_details_8cm">
      <!-- supplier  -->
      <table v-if="supplierCheck" v-table class="table table-white border-dark">
         <thead>
            <tr>
               <th>{{ $t('lang_supplier') }}</th>
               <th v-if="supplier.SpPhone != ''">{{ $t('lang_phone') }}</th>
            </tr>
         </thead>
         <tbody>
            <tr>
               <td>{{ supplier.SpName }}</td>
               <td v-if="supplier.SpPhone != ''">{{ supplier.SpPhone }}</td>
            </tr>
         </tbody>
      </table>

      <!-- customer  -->
      <table v-if="customerCheck" v-table class="table table-white border-dark">
         <thead>
            <tr>
               <th>{{ $t('lang_client') }}</th>
               <th v-if="customer.CustPhone != ''">{{ $t('lang_phone') }}</th>
            </tr>
         </thead>
         <tbody>
            <tr>
               <td>{{ customer.CustName }}</td>
               <td v-if="customer.CustPhone != ''">{{ customer.CustPhone }}</td>
            </tr>
         </tbody>
      </table>

      <!-- driver -->
      <table v-if="driverCheck" v-table class="table table-white border-dark">
         <thead>
            <tr>
               <th>{{ $t('lang_driver_name') }}</th>
               <th v-if="driver.DPhone != ''">{{ $t('lang_phone') }}</th>
            </tr>
         </thead>
         <tbody>
            <tr>
               <td>{{ driver.DName }}</td>
               <td v-if="driver.DPhone != ''">{{ driver.DPhone }}</td>
            </tr>
         </tbody>
      </table>

      <!-- delegates -->
      <table v-if="delegateCheck" v-table class="table table-white border-dark">
         <thead>
            <tr>
               <th>{{ $t('lang_delegate_name') }}</th>
               <th v-if="delegate.DelPhone != ''">{{ $t('lang_phone') }}</th>
            </tr>
         </thead>
         <tbody>
            <tr>
               <td>{{ delegate.DelName }}</td>
               <td v-if="delegate.DelPhone != ''">{{ delegate.DelPhone }}</td>
            </tr>
         </tbody>
      </table>
   </div>
</template>

<script>
import { reactive, toRefs, onUpdated} from 'vue'

export default {
   props: {
      customer: { type: [Object, Boolean], default: false },
      supplier: { type: [Object, Boolean], default: false },
      driver:   { type: [Object, Boolean], default: false },
      delegate: { type: [Object, Boolean], default: false },
      invoice_type: String,
   },

   setup(props) {
      const state = reactive({
         customerCheck: false,
         supplierCheck: false,
         driverCheck: false,
         delegateCheck: false,
      });
      
      if (props.customer !== false) {
         if (!isEmpty(props.customer)) state.customerCheck = true;
      }
      if (props.supplier !== false) {
         if (!isEmpty(props.supplier)) state.supplierCheck = true;
      }
      
      if (!isEmpty(props.driver) && !isEmpty(props.driver.DName)) state.driverCheck       = true;
      if (!isEmpty(props.delegate) && !isEmpty(props.delegate.DelName)) state.delegateCheck = true;

      return {
         ...toRefs(state)
      }
   },
}
</script>

<style scoped lang="scss">
@use '@sass/helpers' as *;

#print_invoice_contact_details_8cm {
   table {
      td,th {
         font-size: rem(12);
      }
   }
}


</style>