<template>
   <div class="print_invoice_note_info_design_2" id="print_invoice_note_info_design_2">
      <div class="note">
         <span v-table-print-design-head-bg-clr="2">{{$t('lang_note')}}</span>
         <p>{{ invoiceNote }}</p>
      </div>

      <span class="sign">{{ $t('lang_name_and_signature_received_shipment') }}</span>

      <div class="invoice-provider">
         <span class="span-label">{{ $t('lang_invoice_provider_name') }}</span> : 
         <span>{{ username }}</span>
      </div>
   </div>
</template>

<script>
import { reactive, toRefs, computed } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n';

export default {
   props: {
      invoiceNote: {type: String, default: ''},
      username:    {type: String, default: ''},
   },
}
</script>


<style lang="scss" scoped>
@use '@sass/helpers' as *;

.print_invoice_note_info_design_2 {
   display: flex;
   flex-direction: column;
   gap: rem(10);
   font-size: rem(14);
   
   
   .sign {
      display: block;
      width: fit-content;
      padding: rem(2.5) rem(5);
      background-color: var(--soft-gray);
   }
   
   .invoice-provider {
      margin-block: rem(15);

      .span-label {
         display: inline-block;
         padding: rem(2.5) rem(5);
         background-color: var(--soft-gray);
      }
   }

   .note {
      display: flex;
      flex-direction: column;
      text-transform: capitalize;
      color: #222;
      
      span {
         padding: rem(3) rem(5);
         text-transform: capitalize; 
         width: fit-content
      }

      p {
         padding: rem(15);
         border: solid rem(1) var(--soft-gray);
      }
   }
}
</style>