<template>
   <!-- <div class="input-wrapper my-1" :class="parentClass"> -->
   <div :class="[parentClass, 'input-wrapper my-1']">
      <button :type="labelBtnType" v-if="labelBtn" :class="labelBtnClass" v-html="labelBtnTxt" :id="labelBtnId || null " @click="btnClick"></button>
      
      <!-- ~~~~~~~~~~~~~~~~~~~~~~~~~ label for input not radio -->
      <label 
         v-if="label && type != 'radio'" 
         :for="id ?? name" 
         class="mb-1" 
         :class="labelClass" 
         v-html="labelHTML ? label : this.$t(label) + `${labelIcon}`"
         @click="labelClick">
      </label>

      <!-- ~~~~~~~~~~~~~~~~~~~~~~~~~ input -->
      <input
         v-if="!isShowPwd && !isShowPhoneCount && inputType == 'input'"
         class="form-control"
         :type="type"
         :name="name"
         :value="modelValue ?? ''"
         :id="id ?? name"
         :placeholder="$t(placeholder)"
         v-focus-hide-place-holder="hidePlaceholderOnFocus"
         :disabled="isDisabled"

         :data-default-val-zero="isZeroEmptyInt || undefined"

         v-bind="$attrs"
         v-only-int="{isInt, isAllowedNegativeValue}"
         v-float-int="{isFloat, isAllowedNegativeValue}"
         v-focus="isFocus"
         v-val-zero-empty-int="isZeroEmptyInt"
         v-liveImg="showLiveImg"

         @focus="focus"
         @blur="blur"
         @click="click"
         @change="change"
      />

      <span 
         v-if="!isShowPwd && !isShowPhoneCount && inputType == 'input' && type == 'radio'" 
         class="radioToCheckBox"
         :style="radioBoxStyle"
      >
         <i class="fas fa-check"></i>
      </span>

      <!-- ~~~~~~~~~~~~~~~~~~~~~~~~~ checkbox -->
      <template v-if="inputType == 'input' && isCheckboxSwitch === true && type == 'checkbox'">
         <span class="switch-left">{{ $t(checkboxSwitchTextLeft) }}</span>
         <span class="switch-right">{{ $t(checkboxSwitchTextRight) }}</span>
      </template>

      <!-- ~~~~~~~~~~~~~~~~~~~~~~~~~ label for radio -->
      <label 
         v-if="label && !isShowPwd && !isShowPhoneCount && inputType == 'input' && type == 'radio'" 
         :for="id ?? name" 
         class="mb-1" 
         :class="labelClass" 
         v-html="labelHTML ? label : this.$t(label) + `${labelIcon}`">
      </label>


      <!-- ~~~~~~~~~~~~~~~~~~~~~~~~~ select  -->
      <select      
         v-if="inputType == 'select'"
         class="form-select"
         :class="isTdSelectBox ? 'td-select' : ''"
         :name="name"
         :value="modelValue ?? ''"
         :id="id ?? name"
         :placeholder="$t(placeholder)"
         :disabled="isDisabled"
         v-bind="$attrs"

         @change="change"
         @click="click"
      >
         <slot></slot>
      </select>

      <!-- ~~~~~~~~~~~~~~~~~~~~~~~~~ textarea  -->
      <textarea     
         v-if="inputType == 'textarea'"
         class="form-control"
         :name="name"
         :value="modelValue ?? ''"
         :id="id ?? name"
         :placeholder="$t(placeholder)"
         :disabled="isDisabled"

         v-bind="$attrs"

         :data-default-val-zero="isZeroEmptyInt || undefined"

         v-only-int="{isInt, isAllowedNegativeValue}"
         v-float-int="{isFloat, isAllowedNegativeValue}"
         v-focus="isFocus"
         v-val-zero-empty-int="isZeroEmptyInt"

         @focus="focus"
         @blur="blur"
      >
         <slot></slot>
      </textarea>

      <!-- ~~~~~~~~~~~~~~~~~~~~~~~~~ extra element  -->
      <span v-if="hasExtraEl" :class="extraElClass" :id="extraElId" v-html="extraElTxt"></span>
      
      <!-- ~~~~~~~~~~~~~~~~~~~~~~~~~ password   -->
      <div v-if="isShowPwd && inputType == 'input'" class="show-pwd">
         <input 
         class="form-control"
         type="password"
         :name="name"
         :value="modelValue ?? ''"
         :id="id ?? name"
         :placeholder="$t(placeholder)"
         :disabled="isDisabled"

         v-only-int="{isInt, isAllowedNegativeValue}"
         v-float-int="{isFloat, isAllowedNegativeValue}"
         v-val-zero-empty-int="isZeroEmptyInt"
         v-focus="isFocus"
         v-bind="$attrs"
         />
         <i class="show-pwd-icon fa-solid fa-eye" data-show-pwd-icon></i>
      </div>
      

      <!-- ~~~~~~~~~~~~~~~~~~~~~~~~~ count phone number  -->
      <div v-if="isShowPhoneCount && inputType == 'input'" class="count-phone-number" data-count-phone-number>
         <input 
            class="form-control"
            :type="type"
            :name="name"
            :value="modelValue ?? ''"
            :id="id ?? name"
            :placeholder="$t(placeholder)"
            :disabled="isDisabled"
            :maxlength="maxlength"

            :data-default-val-zero="isZeroEmptyInt || undefined"
            
            v-bind="$attrs"
            v-count-phone-number="maxlength"
            v-only-int="{isInt, isAllowedNegativeValue}"
            v-float-int="{isFloat, isAllowedNegativeValue}"
            v-val-zero-empty-int="isZeroEmptyInt"
            v-focus="isFocus"
         />
         <span>{{ maxlength }}</span>
      </div>

   </div>
</template>


<script>
   import { onMounted, watch, onUpdated, computed, ref } from 'vue';
   import AirDatepicker from 'air-datepicker';
   import localeEn      from 'air-datepicker/locale/en';
   import { useStore }  from 'vuex';

   export default {
      name: 'BaseInput',
      
      props: {
         // ################################ input props
         type:        {type: String,default: 'text'},
         name:        {type: String,default: ''},
         modelValue:  {type: [String, null],default: null,},
         id:          {type: [String, null],default: null,},
         placeholder: {type: String,default: '',},

         isInt:   {type: Boolean,default: false,},
         isFloat: {type: Boolean,default: false,},

         isZeroEmptyInt:         {type: Boolean,default: false,},
         isAllowedNegativeValue: {type: Boolean,default: false,},
         isLazyZeroEmptyInt:     {type: Boolean,default: true,},

         isDisabled:       {type: Boolean,default: false,},
         showLiveImg:      {type: String,default: '',},
         imgId:            {type: String,default: '',},
         isLazy:           {type: Boolean,default: false},
         isShowPwd:        {type: Boolean,default: false,},
         isFocus:          {type: Boolean,default: false},
         isDate:           {type: Boolean,default: false,},
         showDateClearBtn: {type: Boolean, default: true},
         inputType:        {type: String,default: 'input',},
         
         hidePlaceholderOnFocus: {type: Boolean, default: false},

         isShowPhoneCount: {type: Boolean,default: false,},
         maxlength:        {type: Number,default: 11},
         isTdSelectBox:    {type: Boolean,default: false,},
         
         // ################################ input checkbox
         isCheckboxSwitch:        {type: Boolean,default: true},
         fsCheckboxSwitch:        {type: String,default: '.65rem'},
         checkboxSwitchTextLeft:  {type: String,default: 'ON'},
         checkboxSwitchTextRight: {type: String,default: 'OFF'},


         // ################################ input events
         click:    {type: Function,default: null},
         keyup:    {type: Function,default: null},
         keydown:  {type: Function,default: null},
         keypress: {type: Function,default: null},
         input:    {type: Function,default: null},
         focus:    {type: Function,default: null},
         blur:     {type: Function,default: null},
         change:   {type: Function,default: null},
         

         // ################################ parent props 
         parentClass: {type: String, default: ''},


         // ################################ label props 
         labelClass: { type: String, default: ''},
         label:      { type: [String, Boolean], default: false}, 
         labelIcon : { type: String, default: '',},
         labelHTML:  { type: Boolean, default: false,},


         // ################################ label button props 
         labelBtn:      { type: Boolean, default: false},
         labelBtnType:  { type: String, default: 'submit'},
         labelBtnTxt:   { type: String, default: "<i class='fa-solid fa-magnifying-glass'></i>"},
         labelBtnId:    { type: [String, Boolean], default: false},
         labelBtnClass: { type: String, default: 'btn btn-sm btn-primary'},
         labelClick:    { type: Function, default: null,},
         btnClick:      { type: Function, default: null,},


         // ################################ extra element adding if needed
         hasExtraEl:    { type: Boolean, default: false},
         extraElClass:  { type: String, default: null},
         extraElId:     { type: String, default: null},
         extraElTxt:    { type: String, default: ''},
         radioBoxStyle: { type: String, default: '',},

         
         // ################################ layout
         labelRow:           { type: [Boolean, String], default: false }, 
         labelRowBreakPoint: { type: String, default: 'md' },

      },

      setup(props, context) {
         const type     = props.type;
         const attrs    = context.attrs;
         const store    = useStore();
         const lang     = store.getters['config/getLang'];

         onMounted(() => {
            const selector = `.input-wrapper ${(type == 'radio' || type == 'checkbox') ?  '#' + props.id : `[name="${props.name}"]`}`;
            const input    = document.querySelector(selector);
            
            const wrapper  = props.isShowPhoneCount || props.isShowPwd ? input.parentElement.parentElement : input.parentElement;
            const label    = wrapper.querySelector('label');

            // if type is checkbox or radio remove & add some classes (Bootstrap) & emit event when the input changed
            if (type == 'checkbox' || type == 'radio') {
               input.classList.remove('form-control');
               
               input.addEventListener('change', function() {
                  if (this.checked) context.emit('isChecked');
               });
               
               wrapper.classList.add('form-check');
               input.classList.add('form-check-input');

               if (label) label.classList.add('form-check-label');
            }

            // if the input was date 
            if (props.isDate) {
               input.setAttribute('readonly','');
               input.setAttribute('data-input-date','');

               // el(selector).parentElement.classList.add('mw-120px');
               if (!wrapper.classList.contains('mw-none')) wrapper.classList.add('mw-120px');

               const options = {
                  locale: localeEn,
                  dateFormat: 'yyyy-MM-dd',
                  keyboardNav: true,
                  autoClose: true,
                  position: lang == 'en' ? 'bottom left' : 'bottom right',
                  isMobile: window.innerWidth <= 768 ? true : false,
                  toggleSelected: props.showDateClearBtn,
                  onSelect() {
                     context.emit('update:modelValue', input.value);
                     context.emit('selected', true);
                  }  
               };
               if (props.showDateClearBtn) options.buttons = ['clear'];

               new AirDatepicker(selector, options);
            }

            // remove & add attributes from parent element
            for (let key in context.attrs) {
               if (key == 'onUpdate:modelValue') continue;
               if (key == 'parentClass') input.removeAttribute(key);
               
               if (key == 'class') wrapper.className = 'input-wrapper my-1 ' + props.parentClass;
               else if (key != 'parentClass') wrapper.removeAttribute(key);
            }

            // to watch if class has changed dynamical
            onUpdated(()=> {
               if (!isEmpty(props.labelRow) && props.labelRow !== false) wrapper.className = 'input-wrapper my-1 row align-items-center ' + props.parentClass;
            })


            // set input value with the model value
            if (props.inputType == 'input' && type != 'file' && type != 'radio' && type != 'checkbox') {
               if (props.modelValue !== null) input.value = props.modelValue;
            }

            // update input value => method based on some attributes & input type
            if ((props.isLazy || (props.isZeroEmptyInt && props.isLazyZeroEmptyInt)) && props.inputType == 'input') {
               input.addEventListener('blur', ()=> {
                  context.emit('update:modelValue', input.value)
               });
               
            } else if ((type == 'radio' || type == 'checkbox') && props.inputType == 'input') {

               if (type == 'checkbox' && (input.value.trim() == '' || input.value.trim() === 'true')) {
                  input.checked = props.modelValue;
                  input.setAttribute('checked', props.modelValue);

                  input.addEventListener('change', ()=> context.emit('update:modelValue', input.checked));

               } else input.addEventListener('change', ()=> context.emit('update:modelValue', input.value));
               

               if (props.modelValue === input.value) {
                  input.checked = true;
                  input.setAttribute('checked', true);
               }

            } else if (type == 'file' && props.inputType == 'input') {
               input.addEventListener('change', (e)=> {
                  context.emit('imgSelected', e.target.files[0]);
               });
               
            } else {
               input.addEventListener('input', ()=> {
                  context.emit('update:modelValue', input.value);
               });
            }

            // set input value
            if (props.inputType == 'textarea') {
               input.innerHTML = props.modelValue;
            }


            if (label) {
               if (label.classList.contains('mb-0') || label.classList.contains('my-0')) {
                  label.classList.remove('mb-1', 'mb-2', 'mb-3', 'mb-4', 'mb-5');
               }
            }

            if (wrapper) {
               if (wrapper.classList.contains('my-0')) wrapper.classList.remove('my-1', 'my-2', 'my-3', 'my-4', 'my-5');
               if (wrapper.classList.contains('my-2')) wrapper.classList.remove('my-1', 'my-3', 'my-4', 'my-5');
               if (wrapper.classList.contains('my-3')) wrapper.classList.remove('my-1', 'my-2', 'my-4', 'my-5');
               if (wrapper.classList.contains('my-4')) wrapper.classList.remove('my-1', 'my-2', 'my-3', 'my-5');
               if (wrapper.classList.contains('my-5')) wrapper.classList.remove('my-1', 'my-2', 'my-3', 'my-4');
            }

            // input events 
            if (props.input    != null) input.addEventListener('input', props.input);
            if (props.keyup    != null) input.addEventListener('keyup', props.keyup);
            if (props.keypress != null) input.addEventListener('keypress', props.keypress);
            if (props.keydown  != null) input.addEventListener('keydown', props.keydown);
            

            // if the model changed then make the right input checked 
            if ((type == 'radio' || type == 'checkbox') && props.inputType == 'input') {
               wrapper.style.position = 'relative';

               watch(()=> props.modelValue, (newValue)=> {
                  if (newValue === input.value.trim()) input.checked = true; 
               });
            }


            // checkbox
            if (type == 'checkbox' && props.inputType == 'input' && props.isCheckboxSwitch === true) {
               let div = document.createElement('div');
               div.className = 'switch';
               div.style.fontSize = props.fsCheckboxSwitch;

               let switchLeft  = wrapper.querySelector('.switch-left');
               let switchRight = wrapper.querySelector('.switch-right');

               div.appendChild(input);
               div.appendChild(switchLeft);
               div.appendChild(switchRight);
               
               wrapper.style.cssText = `
                  align-items: center;
                  display: flex;
                  gap: .35rem;
                  padding-right: 0;
               `;

               if (label) {
                  label.classList.remove('mb-1', 'mb-2', 'mb-3', 'mb-4', 'mb-5');
                  label.classList.remove('my-1', 'my-2', 'my-3', 'my-4', 'my-5');
                  label.style.textAlign = "center";
               }

               wrapper.prepend(div);
            }


            // layout
            if (!isEmpty(props.labelRow) && props.labelRow !== false) {
               if (isNaN(parseInt(props.labelRow))) return;

               let cols            = 12;
               let labelCol        = props.labelRow;
               let inputWrapperCol = cols - labelCol;

               let div            = document.createElement('div');
               let labelDiv       = document.createElement('div');
               div.className      = `col-${props.labelRowBreakPoint}-${inputWrapperCol}`;
               labelDiv.className = `col-${props.labelRowBreakPoint}-${labelCol} position-relative`;

               if (!props.isShowPhoneCount && !props.isShowPwd) div.appendChild(input);
               else div.appendChild(input.parentElement);
               labelDiv.appendChild(label);

               wrapper.appendChild(labelDiv);
               wrapper.appendChild(div);
               wrapper.classList.add('row', 'align-items-center');

               label.classList.add('col-form-label', 'd-block', `mx-${props.labelRowBreakPoint}-auto`, 'mw-fit', 'text-center');
               label.classList.remove('mb-1', 'mb-2', 'mb-3', 'mb-4', 'mb-5', 'mb-6');
            }
         });
      }
   }
</script>


<style lang="scss" scoped>
@use '@sass/helpers' as *;
@use '@sass/variables' as *;

.input-wrapper {
   input[type="file"] {
      display: none;
   }

   .radioToCheckBox {
      --bg: #000;
      --text: var(--soft-yellow-clr);
      --border-clr: var(--bg); 
      --transition-delay: .2s;

      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      display: grid;
      place-items: center;
      height: rem(22);
      width: rem(22);
      font-size: rem(14);
      border: solid rem(1) var(--soft-gray);
      color: transparent;
      background-color: white;
      cursor: pointer;

      background-size: 0%;

      &::after {
         content: '';
         position: absolute;
         inset: 0;
         background-color: var(--bg);
         transform: scale(0);
         transition: var(--transition-delay) ease;
      }
      
      i { 
         position: relative;
         color: transparent; 
         z-index: 2;
      }
   }

   input[type='radio'] {
      opacity: 0;
      height: rem(22);
      width: rem(22);
      vertical-align: text-bottom;
      display: inline-block;
      pointer-events: none;

      &:checked ~ .radioToCheckBox { 
         transition: .3s ease, border .3s var(--transition-delay) ease;

         border-color: var(--border-clr);
         color: var(--text);

         &::after {
            transform: scale(1);
         }

         i { 
            color: inherit;
            transition: color .3s var(--transition-delay) ease;
         }
      }
   }
   .radioToCheckBox ~ label { @include br(sm) {white-space: nowrap } }

   select.td-select { 
      background-image: url('~@/assets/imgs/main_icons/dropdown.svg');
   }

}
</style>