const filters = {
   setCurrency(number = 0, currency_type = 'dinar', onlyCurrency = false, isHtml = false, emptyIfZero = false) {
      if (emptyIfZero) {
         if (number.toString().trim() == '' || number.toString().trim() == 0) return '';
      }

      currency_type = currency_type.trim();
      number        += '';
      number        = number.trim();
      
      let lang = getCookie('vue_diamond_pos_lang');
      
      if (onlyCurrency) return currency_type == 'dollar' ? '$' : (lang == 'en' ?  'IQD' : 'د.ع');

      if (number == 0 && !isEmpty(number)) return 0;
      else {
         number = parseFloat(number);
         if (number % 1 !== 0) number = +number.toFixed(2);

         if (currency_type == 'dollar') {
            if (!isHtml) return number.toLocaleString('en-US') + ' $';

            return `
               <span class='d-flex justify-content-between gap-3'>
                  <span>${number.toLocaleString('en-US')}</span>
                  <span>$</span>
               </span>
            `
         } 
         else if (currency_type == 'dinar') {
            let sign = lang == 'en' ? ' IQD' : 'د.ع' ;

            if (!isHtml) return number.toLocaleString('en-US') + ' ' + sign;

            return `
               <span class='d-flex justify-content-between'>
               <span>${number.toLocaleString('en-US')}</span>
               <span>${sign}</span>
               </span>
            `
         }
      } 
   },

   numberFormat(number, pureNumber = false, emptyIfZero = false, sign = false) {
      if (emptyIfZero) {
         if (number.toString().trim() == '' || number.toString().trim() == 0) return '';
      }

      number = parseFloat(number);
      number = (Math.round(number * 100) / 100);
      
      if (number % 1 !== 0) number = +number.toFixed(2);

      if (pureNumber) return number;
      else {
         if (!sign || isEmpty(sign)) return number.toLocaleString('en-US');
         else return number.toLocaleString('en-US') + sign;
      }
   },

   calcDiscountAmount(percentage, price) {
      let discount = (parseFloat(percentage) / 100) * price;

      return numberFormat(discount);
   },

   calcDiscountPercent(discountAmount, price) {
      let discountPercent = (parseFloat(discountAmount) / parseFloat(price)) * 100;

      return numberFormat(discountPercent);
   }
}

export default filters;