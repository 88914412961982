export const sales = [
   {
      path: '/sales',
      name: 'sales',
      component: () => import('../views/sidebar/Sales.vue'),
   },
   {
      path: '/sales/sales_reports/:invoice?/:currency?',
      name: 'sales_reports',
      component: () => import('../views/pages/SalesReports.vue'),
   },
   {
      path: '/sales/invoice_reports/:invoiceId?/:currency?',
      name: 'invoice_reports',
      component: () => import('../views/pages/InvoiceReports.vue'),
   },
   {
      path: '/sales/products_sold_by_customer',
      name: 'products_sold_by_customer',
      component: () => import('../views/pages/ProductsSoldByCustomers.vue'),
   },
   {
      path: '/sales/unsold_customers_report',
      name: 'unsold_customers_report',
      component: () => import('../views/pages/UnsoldCustomersReport.vue'),
   },
   {
      path: '/sales/gifts_sales_reports',
      name: 'gifts_sales_reports',
      component: () => import('../views/pages/GiftsSalesReports.vue'),
   },
   {
      path: '/sales/products_sales_records',
      name: 'products_sales_records',
      component: () => import('../views/pages/ProductsSalesRecords.vue'),
   },
];