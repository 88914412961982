import tables from "./tables";
import global from "./global";
import inputEvents from "./inputs";


const directives = {
   ...global,
   ...tables,
   ...inputEvents,
};

export default directives;