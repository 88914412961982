import ValidateEmpty        from './rules/validateEmpty';
import ValidateZeroValue    from './rules/validateZeroValue';
import ValidateEmail        from './rules/validateEmail';
import ValidateMaximum      from './rules/validateMaximum';
import ValidateMinimum      from './rules/validateMinimum';
import ValidateOnlyNumber   from './rules/validateOnlyNumber';
import ValidatePattern      from './rules/validatePattern';
import ValidateSpecialChar  from './rules/validateSpecialChar';
import ValidateLength       from './rules/validateLength';

import Validation from './rules/validation';

export default class ValidationTrigger {

   validating(names) {
      
      let ValidateClass = new Validation();

      names.forEach( rule => {

         if (rule.notEmpty === true)     ValidateClass  = ValidateClass.addRule(new ValidateEmpty());
         if (rule.notZero === true)      ValidateClass  = ValidateClass.addRule(new ValidateZeroValue());
         if (rule.onlyNumbers === true)  ValidateClass  = ValidateClass.addRule(new ValidateOnlyNumber(rule.float, rule.allowNegativeValue));
         if (isInt(rule.length))         ValidateClass  = ValidateClass.addRule(new ValidateLength(rule.length));
         if (isInt(rule.max))            ValidateClass  = ValidateClass.addRule(new ValidateMaximum(rule.max));
         if (isInt(rule.min))            ValidateClass  = ValidateClass.addRule(new ValidateMinimum(rule.min));
         if (rule.specialChar === true)  ValidateClass  = ValidateClass.addRule(new ValidateSpecialChar(rule.hasSpecialChar));
         if (rule.email === true)        ValidateClass  = ValidateClass.addRule(new ValidateEmail());
         if (rule.checkPattern === true) ValidateClass  = ValidateClass.addRule(new ValidatePattern(rule.type, rule.pattern));
         
         if (rule.showErrNextParent) ValidateClass.addErrNextParent();
         ValidateClass.validate(rule.name);

         if (rule.removeErrMsg === true) ValidateClass.removeErr(rule.name);
      });
      
      if (ValidateClass.errorMsgs.length == 0) return true;
      else return false;
   
   }

   addCustomErr(name, errMsg = [], errNextParent = false) {
      let ValidateClass = new Validation();
   
      if (errNextParent) ValidateClass.addErrNextParent();
      ValidateClass.addCustomErr(name, errMsg);
   }

   rmvCustomErr(name, errNextParent = false) {
      let ValidateClass = new Validation();
   
      if (errNextParent) ValidateClass.addErrNextParent();
      ValidateClass.removeErr(name);
   }

   customErr(condition, name, errMsg = [], errNextParent = false) {

      if (condition) {
         this.addCustomErr(name, errMsg, errNextParent);
         return true;
      } else {
         this.rmvCustomErr(name, errNextParent);
         return false;
      }
   
   }

}