<template>
   <div class="print_invoice_info_design_3" id="print_invoice_info_design_3">
      <div v-if="invoiceNumber" class="invoice_number info">
         <span class="label" v-table-print-design-head-bg-clr="3">{{ $t('lang_invoice_number') }}</span>
         <span>{{ invoiceNumber }}</span>
      </div>

      <div class="invoice_date info">
         <span class="label" v-table-print-design-head-bg-clr="3">{{$t('lang_invoice_date')}}</span>
         <span>{{ date }}</span>
      </div>

      <div class="invoice_time info">
         <span class="label" v-table-print-design-head-bg-clr="3">{{$t('lang_time')}}</span>
         <span>{{ time }}</span>
      </div>
   </div>
</template>

<script>
export default {
   props: {
      invoiceNumber: {type: [String, Boolean],default: false},
      title:         {type: [String, Boolean],default: false},
      date:          {type: String,default: getDate() },
      time:          {type: String,default: getTime() }
   },
}
</script>

<style lang="scss" scoped>
@use '@sass/helpers' as *;

.print_invoice_info_design_3 {
   display: flex;
   align-items: flex-end;
   flex-direction: column;
   gap: rem(5);

   .info {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      gap: rem(5);
      text-align: center;
      text-transform: capitalize;
      color: #222;
      font-size: rem(13);

      span {
         border: solid rem(1) #222;
         padding: rem(3) rem(5);
      }
   }

   .label {
      text-transform: capitalize; 
   }
}
</style>