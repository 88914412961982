export default {
   // user data 
   async setUser(context) {
      let user = await shortFetch(API_URL + 'basic_root_data/get_user', ...skip(4), false);
      if (user.data.ok) context.commit('intiUser', user.data.data);
   },
   
   async updateUser(context, userData = {}) {
      let user = await shortFetch(API_URL + 'users/update_user', userData, 'POST');
      if (user.data.ok) context.dispatch('setUser');
   },


   // auth changing
   changAuth(context, isLogged = false) {
      context.commit('setAuth', isLogged);
   },
}