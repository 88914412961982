export const customers = [
   {
      path: '/customers',
      name: 'customers',
      component: () => import('../views/sidebar/Customers.vue'),
   },
   {
      path: '/customers/add_customer',
      name: 'add_customer',
      component: () => import('../views/pages/AddCustomer.vue'),
   },
   {
      path: '/customers/customer_invoices',
      name: 'customer_invoices',
      component: () => import('../views/pages/CustomerInvoices.vue'),
   },
   {
      path: '/customers/customers_debts',
      name: 'customers_debts',
      component: () => import('../views/pages/CustomersDebts.vue'),
   },
   {
      path: '/customers/customers_reports',
      name: 'customers_reports',
      component: () => import('../views/pages/CustomersReports.vue'),
   },
   {
      path: '/customers/customers_all_debts_reports',
      name: 'customers_all_debts_reports',
      component: () => import('../views/pages/CustomersAllDebtsReports.vue'),
   },
   {
      path: '/customers/customers_accounts',
      name: 'customers_accounts',
      component: () => import('../views/pages/CustomersAccounts.vue'),
   },
   {
      path: '/customers/customers_type',
      name: 'customers_type',
      component: () => import('../views/pages/CustomersType.vue'),
   },
   {
      path: '/customers/customer_sales_records_in_year',
      name: 'customer_sales_records_in_year',
      component: () => import('../views/pages/CustomerSalesRecordsInYear.vue'),
   },
   {
      path: '/customers/reveal_customers_account',
      name: 'reveal_customers_account',
      component: () => import('../views/pages/RevealCustomersAccount.vue'),
   },
];