export const suppliers = [
   {
      path: '/suppliers',
      name: 'suppliers',
      component: () => import('../views/sidebar/Suppliers.vue'),
   },
   {
      path: '/suppliers/add_supplier',
      name: 'add_supplier',
      component: () => import('../views/pages/AddSupplier.vue'),
   },
   {
      path: '/suppliers/reveal_suppliers_account',
      name: 'reveal_suppliers_account',
      component: () => import('../views/pages/RevealSuppliersAccount.vue'),
   },
];